<template>
  <div class="index">
    <div class="indexTop hidden-sm-and-down">
      <div class="tumbSwiper">
        <el-row class="tumbSwiperContainer">
          <!--<el-col :span="6" class="tumbSwiperText">-->
          <!--  <p class="title">Upcoming session</p>-->
          <!--  <p class="subTitle">sssd asdas asd asd sad </p>-->
          <!--  <p class="des">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda explicabo laborum-->
          <!--    quisquam? Aliquid-->
          <!--    dolores eveniet labore optio possimus quisquam, rem voluptas voluptatibus. Cumque dolor eligendi iure-->
          <!--    nihil numquam, perspiciatis quis.</p>-->
          <!--  <p class="bottomText">Only ONE CAT ALLOWED > </p>-->
          <!--</el-col>-->

          <el-col :md="7" :lg="7" class="tumbSwiperText">
            <div class="hidden-sm-and-down">
              <swiper
                  :modules="[Thumbs]"
                  watch-slides-progress
                  @swiper="setThumbsSwiper"
                  :multipleActiveThumbs="swiper_options.multipleActiveThumbs"
                  :watchSlidesVisibility="swiper_options.watchSlidesVisibility"
                  :watchSlidesProgress="swiper_options.watchSlidesProgress"
                  class="tumbSwiperBottom hidden-sm-and-down swiper-container swiper-no-swiping"
                  style="cursor:pointer"
              >
                <swiper-slide style="position:relative">
                  <p class="title">{{ carouselData[activeIndex].title }}</p>
                  <p class="subTitle">{{ carouselData[activeIndex].sub_title }} </p>
                  <p class="des">{{ carouselData[activeIndex].description }}</p>
                  <p class="bottomText" @click="clickBottomText(carouselData[activeIndex])">{{ carouselData[activeIndex].button_lable }} > </p>
                </swiper-slide>
              </swiper>
            </div>
          </el-col>
          <el-col :xs="4" :md="5" :lg="5" class="smallImg hidden-sm-and-down">
            <swiper
                :modules="[Thumbs]"
                watch-slides-progress
                @swiper="setThumbsSwiper"
                :multipleActiveThumbs="swiper_options.multipleActiveThumbs"
                :watchSlidesVisibility="swiper_options.watchSlidesVisibility"
                :watchSlidesProgress="swiper_options.watchSlidesProgress"
                class="hidden-sm-and-down swiper-container swiper-no-swiping"
                style="cursor:pointer"
            >
              <swiper-slide v-for="(item,index) in carouselSubData" @click="clickCarouselImg(item)"
                            style="position:relative">
                <div class="imgContainer" style="height:125px">
                  <img :src="item.image" alt="" class="innerBg" style="height:100%">
                  <p class="subTumbText">{{ item.label }}</p>
                  <div class="grayMask"></div>
                </div>

                <!--<div class="innerBg"-->
                <!--     :style="{ backgroundImage: `url(${item.image})`,paddingBottom:'50%',backgroundSize:'cover',overflow:'hidden',borderRadius:'15px',margin:'0 7px',maxHeight:'200px'}">-->
                <!--  <p class="textColor">{{ item.label }}</p>-->
                <!--</div>-->
              </swiper-slide>
            </swiper>
          </el-col>
          <el-col :sm="24" :md="12" :lg="12" class="tumbSwiperContent">
            <swiper
                :autoplay="swiper_options.autoplay"
                :thumbs="{ swiper: thumbsSwiper }"
                :slides-per-view="swiper_options.slidesPerView"
                :pagination="swiper_options.pagination"
                :effect="swiper_options.effect"
                @slideChange="slideChange"
                class="tumbSwiperTop"
            >
              <div class="swiper-pagination"></div>
              <swiper-slide v-for="(item,index) in carouselData" :key="index" v-slot="{ isActive }">
                <!--<img class="carouselBg" :src="item.bg_image" alt="">-->

                <div class="topBg" :style="{ backgroundImage: `url(${item.bg_image})`}">
                  <div class="topText" style="position: absolute;left:20px">
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <!--轮播图左上角的缩略小图-->
            <!--<div class="smallImg hidden-sm-and-down">-->
            <!--  <p v-for="(item,index) in carouselData" :style="{ backgroundImage: `url(${item.bg_image})`}">-->
            <!--  </p>-->
            <!--</div>-->
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="tumbSwiper hidden-md-and-up">
      <el-row class="tumbSwiperContainer">
        <el-col :sm="24" :md="24" class="tumbSwiperContent">
          <swiper
              :autoplay="swiper_options.autoplay"
              :thumbs="{ swiper: thumbsSwiper }"
              :slides-per-view="swiper_options.slidesPerView"
              :pagination="swiper_options.pagination"
              @slideChange="slideChange"
              class="tumbSwiperTop"
          >
            <div class="swiper-pagination"></div>
            <swiper-slide v-for="(item,index) in carouselData" v-slot="{ isActive }">
              <img class="carouselBg" :src="item.bg_image" alt="" style="width:100%;">
            </swiper-slide>
          </swiper>
        </el-col>
      </el-row>
    </div>
    <!-- 文字放下来 -->
    <div class="indexBottom hidden-md-and-up">
      <div class="indexBottomText">
        <el-row :gutter="gutter" class="indexBottomImgsRow">
          <el-col>
            <p class="title">{{ carouselData[activeIndex].title }}</p>
            <p class="subTitle">{{ carouselData[activeIndex].sub_title }} </p>
            <p class="des">{{ carouselData[activeIndex].description }}</p>
            <p class="bottomText" @click="clickBottomText(carouselData[activeIndex])">{{ carouselData[activeIndex].button_lable }} > </p>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 缩略图放下来 -->
    <div class="indexBottom hidden-md-and-up">
      <div class="indexBottomImgs">
        <el-row :gutter="gutter" class="indexBottomImgsRow">
          <el-col
              :md="{span:6}"
              :sm="{span:24}"
              v-for="item in carouselSubData"
              :key="item"
              class="oneBottom"
              @click="clickPopular(item)"
          >
            <img :src="item.image" style="max-height: 200px;object-fit: cover" alt=""/>
            <span :style="{left:gutter/2+'px'}">{{ item.name || item.label }}</span>
          </el-col>
        </el-row>
      </div>
    </div>


    <div class="indexBottom">
      <p class="title textColor">{{ bottomImgData.secondary_title }}</p>
      <div class="indexBottomImgs">
        <el-row :gutter="gutter" class="indexBottomImgsRow">
          <el-col
              :md="{span:6}"
              :sm="{span:24}"
              v-for="item in bottomImgData.data"
              :key="item"
              class="oneBottom"
              @click="clickPopular(item)"
          >
            <img :src="item.image" style="max-height: 200px;object-fit: cover" alt=""/>
            <span :style="{left:gutter/2+'px'}">{{ item.name || item.label }}</span>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref, toRefs, onBeforeMount, onMounted, getCurrentInstance} from "vue";
import {useRouter} from 'vue-router';
import axios from '@/api/http.js'
import imgBox from '@/components/page/imgBox.vue';
import {Thumbs} from 'swiper';
import 'element-plus/theme-chalk/display.css';

export default defineComponent({
  name: "Home",
  components: {
    imgBox
  },
  setup(props, ctx) {
    window.addEventListener("resize", function () {
      let width = window.innerWidth;
      if (width <= 992) {
        data.showCarouselText = true;
      } else {
        data.showCarouselText = false;
      }
    })
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => {
      // console.log("==== swiper!!!!=====", swiper)
      thumbsSwiper.value = swiper;
    };
    const swiper_options = reactive({
      activeIndex: 1,
      slidesPerView: 1,
      autoplay: {
        delay: 8000,
        disableOnInteraction: false
      },
      loop: true,
      speed: 500,
      pagination: {
        clickable: true
      },
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
      observer: true,//observer=true//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//observeParents=true//修改swiper的父元素时，自动初始化swiper
      multipleActiveThumbs: false,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    })
    const router = useRouter();
    // const {proxy} = getCurrentInstance();
    const client = localStorage.getItem("eventClient");
    const setClientStyle = () => {
      if (client == 'inpixon') {
        data.gutter = 14;
      } else {
        data.gutter = 20;
      }
    }
    const data = reactive({
      activeIndex: 0,//轮播图当前active的index
      showCarouselText: true,//轮播图里面的文字在992px下放到下面单独成行
      gutter: '',//轮播图里面图片的左右距离
      ttt: '',
      // testImg:'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
      carouselData: [
        {}//很重要，必须要有这么一个空的对象，不然页面刚加载是空白的，要等定时器走完第一个时间才显示
        // {
        //   img: require("../../assets/imgs/carousel1.jpeg"),
        //   firstTitle: "Content Hightlight",
        //   subTitle: "VMwaretanzu",
        //   description:
        //       "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam molestias dicta, quaerat nulla minus eos. Exercitationem eius provident consequuntur ea voluptatum. Similique cum ex reprehenderit. Omnis iusto voluptatem eligendi ea.",
        //   subImg: [
        //     {img: require("../../assets/imgs/carouselBottom.jpg")},
        //     {img: require("../../assets/imgs/carouselBottom.jpg")},
        //     {img: require("../../assets/imgs/carouselBottom.jpg")},
        //   ],
        // },
      ],
      carouselSubData: [],
      bottomImgData: {},
      previewImg: require("@/assets/imgs/test.jpg"),
    });
    // 方法===============================
    const clickCarouselImg = (data) => {
      console.log("data!!!!!", data);
      let data2 = JSON.parse(JSON.stringify(data));
      if (data2.action_target == "inner") {
        router.push(data.action_url);
        // router.push({
        //   name: 'Content',
        //   query: {id: data2.action_url},
        // });
      } else {
        if (data2.action_url == "") {
          return
        } else {
          window.open(data2.action_url, "_blank")
        }
      }
      window['gtag']('event', 'Overview Sub Banner click', {
        event_category: 'Secondary Banner',
        event_label: data2.label,
        send_to: "UA-104780762-24",
      })
    };
    const formatData = (data) => {
      let arr = [];
      data.map((item, index) => {
        let obj = {};
        obj.bg_image = item.bg_image;
        obj.button_lable = item.button_lable;
        obj.button_target = item.button_target;
        obj.button_url = item.button_url;
        obj.description = item.description;
        obj.sub_title = item.sub_title;
        obj.title = item.title;
        obj.sub_banner = item.sub_banner;
        arr.push(obj);
      })
      return arr
    }
    const formatSubData = (data) => {
      let arr = [];
      data.map(item => {
        let obj = {};
        obj.image = item.image;
        obj.label = item.label;
        obj.action_target = item.action_target;
        obj.action_url = item.action_url;
        arr.push(obj);
      })
      return arr
    }
    const getData = () => {
      axios.request({
        method: 'PATCH',
        url: '/virtual/home',
        data: {
          meetingId: "a7yyxczuty0qpjo2393gmyad",
          // meetingId: localStorage.eventsMeetingId,
          operationType: "home",
        }
      }).then(res => {
        // console.log("secondary:", res.data.data[0].secondary);
        data.carouselData = formatData(res.data.data[0].banner);
        data.carouselSubData = formatSubData(res.data.data[0].sub_banner);
        data.bottomImgData = res.data.data[0].secondary;
        // console.log("data.bottomImgData:", data.bottomImgData)
      })
    }
    getData();
    const clickCarouselBtn = (data) => {
      let data2 = JSON.parse(JSON.stringify(data));
      if (data2.button_target == 'inner') {
        router.push(data.button_url);
        // router.push({
        //   name: 'Content',
        //   query: {id: data2.button_url},
        // });
      } else {
        window.open(data2.button_url, "_blank")
      }
    }
    const clickPopular = (data) => {
      if (data.action_target == 'inner') {
        router.push(data.action_url);
        // router.push({
        //   name: 'Content',
        //   query: {id: data.action_url},
        // });
      } else {
        if (data.action_url == "") {
          return
        } else {
          window.open(data.action_url, "_blank")
        }
      }
    }
    setClientStyle();
    const getWindowWidth = () => {
      let width = window.innerWidth;
      if (width <= 992) {
        data.showCarouselText = true;
      } else {
        data.showCarouselText = false;
      }
    }
    const slideChange = (swiper) => {
      console.log("==== swiper=====", swiper.activeIndex)
      data.activeIndex = swiper.activeIndex;
    }
    getWindowWidth();
    const clickBottomText = (d)=>{
      console.log("====d =====",d)
      if(d.button_target=='inner'){
        router.push(d.button_url);
      }else if(d.button_target=='external'){
        window.open(d.button_url)
      }else{
        return
      }
    }
    return {
      ...toRefs(data),
      clickCarouselImg,
      getData,
      formatData,
      formatSubData,
      clickCarouselBtn,
      clickPopular,
      setClientStyle,
      swiper_options,
      Thumbs,
      thumbsSwiper,
      setThumbsSwiper,
      getWindowWidth,
      slideChange,
      clickBottomText
    };
  },
});
</script>
<!--<style scoped lang='scss'>-->

<!--.el-image {-->
<!--  display: block;-->
<!--  min-height: 200px;-->
<!--  margin-bottom: 10px;-->
<!--}-->

<!--.index {-->
<!--  display: flex;-->
<!--  flex-flow: column;-->

<!--  .indexTop {-->
<!--    height: 500px;-->
<!--    //height: 70%;-->
<!--    //min-height:500px;-->
<!--    overflow: hidden;-->
<!--    .tumbSwiper {-->
<!--      height:100%;-->
<!--      min-height: 500px;-->
<!--      position: relative;-->
<!--      .tumbSwiperTop {-->
<!--        height:100%;-->
<!--        .topBg{-->
<!--          height:100%;-->
<!--          //min-height: 500px;-->
<!--          background-size: cover;-->
<!--          img {-->
<!--            width: 100%;-->
<!--          }-->

<!--          .topText {-->
<!--            margin-bottom: 25px;-->
<!--          }-->

<!--          .carouselDataFirstTitle {-->
<!--            font-size: 35px;-->
<!--            //color: #000000;-->
<!--            font-weight: bold;-->
<!--          }-->

<!--          .carouselDataSubTitle {-->
<!--            font-size: 25px;-->
<!--            //color: #000000;-->
<!--            font-weight: bold;-->
<!--            padding: 20px 0 10px 0;-->
<!--          }-->

<!--          .carouselDataDes {-->
<!--            font-size: 15px;-->
<!--            color: #717074;-->
<!--          }-->
<!--        }-->
<!--      }-->

<!--      .tumbSwiperBottom {-->
<!--        position: absolute;-->
<!--        bottom: 30px;-->
<!--        width: 100%;-->

<!--        p {-->
<!--          color: #fff;-->
<!--          font-family: OpenSans-Semibold;-->
<!--          font-size: 20px;-->
<!--          position: absolute;-->
<!--          bottom: 20px;-->
<!--          left: 20px;-->
<!--          width: 90%-->
<!--        }-->

<!--        //img {-->
<!--        //  width: 100%;-->
<!--        //  float: left;-->
<!--        //}-->
<!--        //p{-->
<!--        //  float: left;-->
<!--        //  margin-left: 20px;-->
<!--        //  margin-top: -40px;-->
<!--        //  color:#fff;-->
<!--        //  font-family: OpenSans-Semibold;-->
<!--        //  font-size: 20px;-->
<!--        //}-->
<!--      }-->
<!--    }-->

<!--    .bottomImgContainer {-->
<!--      width: 100%;-->
<!--      position: absolute;-->
<!--      bottom: 0;-->
<!--      padding: 0 30px 30px 30px;-->
<!--      box-sizing: border-box;-->

<!--      .block {-->
<!--        cursor: pointer;-->
<!--        box-shadow: 0px 0px 5px #c5c7ca;-->
<!--        display: flex;-->
<!--        flex-flow: column;-->

<!--        img {-->
<!--          width: 100%;-->
<!--          max-height: 190px;-->
<!--        }-->

<!--        p {-->
<!--          background: #f7f7f8;-->
<!--          font-size: 14px;-->
<!--          font-weight: bold;-->
<!--          padding: 10px 10px;-->
<!--        }-->
<!--      }-->
<!--    }-->
<!--  }-->

<!--  .indexBottom {-->
<!--    flex: 1;-->
<!--    font-size: 20px;-->
<!--    font-weight: bold;-->
<!--    color: #1d4290;-->
<!--    //padding: 30px;-->
<!--    padding: 15px 30px 30px 30px;-->
<!--    height: 30%;-->
<!--    display: flex;-->
<!--    flex-flow: column;-->
<!--    //margin: 0 15px;-->

<!--    .title {-->
<!--      margin-bottom: 10px;-->
<!--    }-->

<!--    .indexBottomImgs {-->
<!--      //height: 100%;-->
<!--      display: flex;-->
<!--      align-items: center;-->
<!--      //padding: 0 30px;-->
<!--      img {-->
<!--        width: 100%;-->
<!--        cursor: pointer;-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</style>-->
<style scoped lang='scss'>

.el-image {
  display: block;
  min-height: 200px;
  margin-bottom: 10px;
}

.index {
  display: flex;
  flex-flow: column;

  .indexTop {
    height: 500px;
    //height: 70%;
    //min-height:500px;
    overflow: hidden;
    //box-shadow: 0px 10px 7px #dbd5d5;
    //padding-bottom: 30px;

    .tumbSwiper {
      height: 100%;
      //min-height: 500px;
      position: relative;

      .tumbSwiperContainer {
        height: 100%;

        .tumbSwiperText {
          height: 100%;
          color: #fff;
          //padding: 50px 30px 0 50px;
          padding: 30px 30px 0 30px;

          .title {
            color: #ff8201;
            font-size: 35px;
            font-family: OpenSans-ExtraBold;
          }

          .subTitle {
            color: #052a4b;
            font-size: 16px;
            font-family: OpenSans-ExtraBold;
            margin: 10px 0;
          }

          .des {
            color: #6f6f6f;
            font-size: 20px;
            margin-bottom: 10px;
          }

          .bottomText {
            color: #ff8201;
            font-size: 20px;
            font-family: OpenSans-ExtraBold;
          }
        }

        //轮播图左上角的缩略小图
        .smallImg {
          z-index: 999;
          background-size: cover;
          height: 500px;
          width: 250px;

          .grayMask {
            position: absolute;
            top: 0;
            left: 0;
            background: #ff8300;
            opacity: 0.77;
            width: 100%;
            height: 100%;
          }

          .innerBg {
            height: 120px;
            width: 100%;
            object-fit: cover;
          }

          p {
            height: 25%;
            background-size: cover;
            z-index: 99;
          }

          .subTumbText {
            color: #fff;
            position: absolute;
            left: 10px;
            bottom: 10px;
            //font-size: 20px;
            font-size: 18px;
            font-family: 'OpenSans-ExtraBold';
          }

          //img{
          //  width:200px;
          //  margin: 20px;
          //}
        }

        .tumbSwiperContent {
          height: 100%;
          position: relative;

          .carouselBg {
            object-fit: cover;
          }

          .topBg {
            background-size: cover;
            background-position: center;
            height: 500px;
          }


        }
      }

      //.tumbSwiperTop {
      //  height:100%;
      //  .topBg{
      //    height:100%;
      //    //min-height: 500px;
      //    background-size: cover;
      //    img {
      //      width: 100%;
      //    }
      //
      //    .topText {
      //      margin-bottom: 25px;
      //    }
      //
      //    .carouselDataFirstTitle {
      //      font-size: 35px;
      //      //color: #000000;
      //      font-weight: bold;
      //    }
      //
      //    .carouselDataSubTitle {
      //      font-size: 25px;
      //      //color: #000000;
      //      font-weight: bold;
      //      padding: 20px 0 10px 0;
      //    }
      //
      //    .carouselDataDes {
      //      font-size: 15px;
      //      color: #717074;
      //    }
      //  }
      //}
      //
      //.tumbSwiperBottom {
      //  position: absolute;
      //  bottom: 30px;
      //  width: 100%;
      //
      //  p {
      //    color: #fff;
      //    font-family: OpenSans-Semibold;
      //    font-size: 20px;
      //    position: absolute;
      //    bottom: 20px;
      //    left: 20px;
      //    width: 90%
      //  }
      //
      //  //img {
      //  //  width: 100%;
      //  //  float: left;
      //  //}
      //  //p{
      //  //  float: left;
      //  //  margin-left: 20px;
      //  //  margin-top: -40px;
      //  //  color:#fff;
      //  //  font-family: OpenSans-Semibold;
      //  //  font-size: 20px;
      //  //}
      //}
    }

    .bottomImgContainer {
      width: 100%;
      position: absolute;
      bottom: 0;
      padding: 0 30px 30px 30px;
      box-sizing: border-box;

      .block {
        cursor: pointer;
        box-shadow: 0px 0px 5px #c5c7ca;
        display: flex;
        flex-flow: column;

        img {
          width: 100%;
          max-height: 190px;
          max-width: 400px;
        }

        p {
          background: #f7f7f8;
          font-size: 14px;
          font-weight: bold;
          padding: 10px 10px;
        }
      }
    }
  }

  .indexBottom {
    flex: 1;
    font-size: 20px;
    font-weight: bold;
    color: #1d4290;
    //padding: 30px;
    padding: 15px 30px 30px 30px;
    //height: 30%;
    display: flex;
    justify-content: end;
    flex-flow: column;
    //margin: 0 15px;
    .oneBottom{
      img{
        cursor: pointer;
      }
      span{
        cursor: pointer;
      }
    }
    .title {
      margin-bottom: 10px;
    }

    .indexBottomText {
      .title {
        color: #ff8201;
        font-size: 25px;
        font-family: OpenSans-ExtraBold;
      }

      .subTitle {
        color: #052a4b;
        font-size: 16px;
        font-family: OpenSans-ExtraBold;
        margin: 10px 0;
      }

      .des {
        color: #6f6f6f;
        font-size: 20px;
        margin-bottom: 10px;
      }

      .bottomText {
        color: #ff8201;
        font-size: 20px;
        font-family: OpenSans-ExtraBold;
      }
    }

    .indexBottomImgs {
      //height: 100%;
      display: flex;
      align-items: center;
      //padding: 0 30px;
      img {
        width: 100%;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss">
.index {
  .smallImg {
    .swiper-wrapper {
      flex-flow: column;
    }
  }

  .el-carousel {
    height: 100%;

    .el-carousel__container {
      height: 100%;
    }
  }
}

</style>

<style lang="scss">
@media screen and (min-width: 320px) and (max-width: 992px) {
  .index .indexTop .tumbSwiper .tumbSwiperTop .topBg .carouselDataFirstTitle {
    font-size: 25px !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .index .indexBottom .indexBottomImgs .oneBottom span{
    font-size: 12px!important;
    height: 40px;
    line-height: 40px;
    width:80%;
  }
  .index .indexTop .tumbSwiperText {
    .title {
      font-size: 30px !important;
    }

    .subTitle {
      font-size: 14px !important;
    }

    .des {
      font-size: 18px !important;
    }

    .bottomText {
      font-size: 18px !important;
    }
  }
  .index .indexTop .smallImg .subTumbText {
    font-size: 12px !important;
  }

}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .index .indexBottom .indexBottomImgs .oneBottom span{
    height: 40px;
    line-height: 40px;
    font-size: 16px!important;
  }
  .index .indexTop .tumbSwiperText {
    .title {
      font-size: 30px !important;
    }

    .subTitle {
      font-size: 14px !important;
    }

    .des {
      font-size: 18px !important;
    }

    .bottomText {
      font-size: 18px !important;
    }
  }
  .index .indexTop .smallImg .subTumbText {
    font-size: 14px !important;
  }

}
</style>


